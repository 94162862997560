#page-container{
    max-width: 1600px;
    margin: 0 auto;
}

.main-screen{
  padding-bottom: 50px;
  padding-top: 50px;
}

.main-screen button{
    width: 100%;
    border-radius: 0;
    font-size: 1.4em;
}

.cm-s-material {
    height: 720px !important;
}

.login form{
    padding: 10px;
}

/* old main */
.debug {
    background-color: rgb(185, 185, 155);
}

* {
    box-sizing: border-box;
}
/* http://meyerweb.com/eric/tools/css/reset/
   v2.0 | 20110126
   License: none (public domain)
*/
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font: inherit;
    vertical-align: baseline;
}

/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
    display: block;
}

body {
    line-height: 1;
}

ol, ul {
    list-style: none;
}

blockquote, q {
    quotes: none;
}

blockquote:before, blockquote:after,
q:before, q:after {
    content: '';
    content: none;
}

table {
    border-collapse: collapse;
    border-spacing: 0;
}

/*main*/
h1 {
    font-size: 140%;
}

h2 {
    font-size: 130%;
}

h3 {
    font-size: 120%;
}

h4 {
    font-size: 110%;
}

.warning {
    background-color: orange;
}

.success {
    background-color: rgb(28, 176, 28);
}

body {
    font-size: 20px;
    line-height: 1.3;
    font-family: sans-serif;
}

.content {
    width: 100%;
    margin: 15px auto;
}

.block-center {
    display: inline-block;
}

button.btn-lg {
    cursor: pointer;
    padding: 5px 25px;
    background: lightblue;
    border: 1px solid lightsteelblue;
    -moz-border-radius: 10px;
    -webkit-border-radius: 10px;
    border-radius: 5px;
    /*give the button a drop shadow*/
    -webkit-box-shadow: 0 0 4px rgba(0, 0, 0, .75);
    -moz-box-shadow: 0 0 4px rgba(0, 0, 0, .75);
    box-shadow: 0 0 4px rgba(0, 0, 0, .75);
    /*style the text*/
    color: black;
    font-size: 1.1em;
    padding: 10px;
    min-width: 200px;
}

button.btn-lg:hover, button.btn-lg:focus {
    background-color: cornflowerblue;
    -webkit-box-shadow: 0 0 1px rgba(0, 0, 0, .75);
    -moz-box-shadow: 0 0 1px rgba(0, 0, 0, .75);
    box-shadow: 0 0 1px rgba(0, 0, 0, .75);
}

.ui-btn-sm {
    display: inline-block;
    width: 200px;
    padding: 14px;
    margin: 10px;
}

.ui-btn-xs {
    display: inline-block;
    width: 150px;
    padding: 14px;
    margin: 10px;
    border-radius: 4px;
}

.ui-btn-xxs {
    display: inline-block;
    width: 100px;
    padding: 14px;
    margin: 10px;
    border-radius: 4px;
}

.ui-btn-lg {
    display: inline-block;
    width: 220px;
    padding: 20px;
    margin: 20px;
    border-radius: 4px;
}

/*mainly index.html*/
div#mainNav a {
    font-size: x-large;
    line-height: 4em;
    width: 260px;
    float: left;
    text-align: center;
    text-decoration: none;
    text-transform: uppercase;
    border: 5px solid white;
}

div#mainNav {
    display: flex;
    flex-direction: column;
    align-items: center;
    display: block;
    width: 800px;
    margin: 20px auto;
}

header {
    border-width: 1px 0;
    border-style: solid;
    position: relative;
    background-color: #1d1d1d;
    border-color: #1b1b1b;
    color: #fff;
    font-weight: 700;
    display: block;
    padding: 5px;
}

header #theirworld-title {
    background-color: #1d1d1d;
    color: white;
    font-size: 30pt;
    padding: 5px;
    text-align: center;
}

.ui-title {
    font-size: 1.4rem;
    min-height: 1rem;
    text-align: center;
    display: block;
    overflow: hidden;
    white-space: nowrap;
    color: white;
    margin-bottom: 12px;
}

.ui-default {
    color: white;
}

.ui-client {
    color: #fc0;
}

.ui-title a {
    color: white;
    text-decoration: none;
    font-size: 1em;
}

#preloader {
    width: 300px;
    height: 300px;
    margin: 30px auto;
}

#preloader img {
    width: 100%;
    height: 100%;
}

/* Starting to refactor in 3rd party */
.bookspan-container {
    background-color: lightgreen;
    color: black;
}

.ngs-container {
    background-color: #fc0;
    color: black;
}

.vetz-container {
    background-color: red;
    color: black;
}

.grey-container {
    background-color: grey;
    color: white;
}


.default a {
    font-size: x-large;
    line-height: 4em;
    width: 260px;
    float: left;
    text-align: center;
    text-decoration: none;
    text-transform: uppercase;
    border: 5px solid white;
    background-color: lightgray;
}

.default .options {
    display: block;
    width: 80%;
    margin: 20px auto;
}

div#receivers input {
    font-size: xx-large;
}

div#receivers div[data-role="content"] {
    /*width: 80%;*/
    margin-top: 90px;
}

div[data-role="fieldcontain"] {
    display: block;
    padding: 0 25%;
}

div#receivers div[data-role="content"] button {
    margin: 20px 40% 0 40%;
}

#receivers, #receiversItem {
    display: none;
}

#receiversItem img {
    box-sizing: border-box;
    width: 30%;
    max-height: 500px;
    overflow: hidden;
    /*height: 250px;*/
    float: right;
    padding: 0 20px;
}

#receiversItem #details {
    box-sizing: border-box;
    float: left;
    width: 70%;
    padding: 0 20px;
}


#item {
    display: inline-block;
    box-sizing: border-box;
    float: left;
    width: 75%;
}

#item img {
    padding: 10px;
    display: inline-block;
    box-sizing: border-box;
    float: left;
    width: 40%;
}

#item ul {
    padding: 10px;
    display: inline-block;
    box-sizing: border-box;
    float: left;
    width: 60%;
}

#leftReceivers {
    display: inline-block;
    box-sizing: border-box;
    float: left;
    width: 20%;
    overflow: hidden;
    padding: 10px;
}

#list {
    display: inline-block;
    box-sizing: border-box;
    float: left;
    width: 100%;
}

#list p {
    font-size: small;
}

#list ul {
    display: inline-block;
    box-sizing: border-box;
    float: left;
    width: 90%;
    margin: 5%;
    height: 200px;
    overflow-y: auto;
}

#detailsDiv {
    box-sizing: border-box;
    display: block;
    /*width: 60%;
    float: right;*/
    padding-top: 10px;
}

#detailsDiv label {
    font-size: small;
}

#detailsImg {
    box-sizing: border-box;
    width: 160px;
    height: 160px;
    padding: 20px;
}

#detailsForm {
    width: 98%;
}

.inputLabelWrapper {
    width: 100%;
    display: inline-block;
}

.inputLabelWrapperHalf {
    width: 50%;
    display: inline-block;
}

.inputLabelWrapperThird {
    width: 30%;
    display: inline-block;
}

.inputLabelWrapperFourth {
    width: 23%;
    display: inline-block;
}

.inputLabelWrapperFifth {
    width: 18%;
    display: inline-block;
}

.inputLabelWrapperSixth {
    width: 14%;
    display: inline-block;
}

label {
    float: left;
}

input {
    width: 100%;
    font-size: larger;
}

#rightDiv {
    float: right;
    box-sizing: border-box;
    display: block;
    width: 20%;
}

button {
    font-size: 1.2em;
}

.required {
    border: 1px solid red;
}

.note {
    color: red;
    font-size: small;
}

/* Double-sized Checkboxes */
input[type="checkbox"] {
    width: 13px;
    height: 13px;
    margin:0;
    vertical-align: bottom;
    position: relative;
    top: -1px;
    *overflow: hidden;
    -ms-transform: scale(2);
    -moz-transform: scale(2);
    -webkit-transform: scale(2);
    -o-transform: scale(2);
    padding: 10px;
    margin-left: 10px;
}

label.checkbox {
    display: block;
    padding-left: 15px;
    text-indent: -15px;
}

.locationTableDiv {
    margin-top: 30px;
}

.locationTableDiv > p {
    margin-bottom: 10px;
}

.locationTable {
    margin-bottom: 20px;
    width: 30%;
}

#locationTableType {
    width: 25%;
}

.locationTableData {
    border: 1px solid black;
}

.locationTableData td {
    padding: 10px;
}

.returnInput > input {
    width: 65%;
    float: right;
    font-size: larger;
}

/*
 Movers
*/

#login {
    display: none;
    color: lightgreen;
    font-size: large;
    padding-top: 5px;
    float: right;
    cursor: pointer;
}

#logout {
    display: none;
    color: lightgreen;
    font-size: large;
    padding-top: 5px;
    float: right;
    cursor: pointer;
}

.ModalBackdrop {
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background: rgba(31, 26, 26, 0.73);
}

.ModalContent {
    background: white;
    margin-left: auto;
    margin-right: auto;
    margin-top: 0px;
    width: 382px;
    padding: 30px;
    text-align: center;
}

.ModalContent input {
    line-height: 2em;
    font-size: large;
    width: 180px;
}

ul {
    list-style: none;
    display: inline-block;
    padding-left: 0; /* To override jquery mobile css */
}

img {
    width: 200px;
    height: 280px;
}

.list {
    display: inline-block;
    box-sizing: border-box;
    width: 45%;
    float: left;
}

.item {
    display: inline-block;
    box-sizing: border-box;
    width: 45%;
    float: right;
}

.item-title {
    font-weight: bold;
    max-width: 200px;
}

.item-qty,
.item-pickup,
.item-dropoff {
    font-weight: bold;
    font-size: x-large;
}

.item-qty input {
    font-size: 24px;
    width: 100px;
}


.movesTable td {
    text-align: center;
    vertical-align: middle;
    border-right: 1px solid grey;
    padding: 6px;
}

.movesTable thead {
    height: 20px;
}

.movesTable tbody tr {
    height: 70px;
    text-shadow: none;
    font-size: 22px;
    border-bottom: 1px solid black;
}

.move-owner-row {
    height: 20px;
}

hr {
    clear: both;
}

#item {
    display: inline-block;
    box-sizing: border-box;
    float: left;
    width: 48%;
    font-size: 24px;
}

#item img {
    padding: 10px;
    display: inline-block;
    box-sizing: border-box;
    float: left;
    width: 40%;
}

#item ul {
    padding: 10px;
    display: inline-block;
    box-sizing: border-box;
    float: left;
    width: 60%;
}

#item button {
    font-size: 18px;
}

#list {
    display: inline-block;
    box-sizing: border-box;
    float: left;
    width: 48%;
    margin: 10px;
    height: 500px;
    overflow-y: scroll;
}

#list table {
    box-sizing: border-box;
    width: 100%;
}

.content {
    margin-top: 0;
}

.itemDetails {
    height: 365px;
}

.goToBay {
    width: 50px;
    height: 50px;
    font-size: 1.3em;
    margin: 5px;
    cursor: pointer;
}

/*bank choices*/
#bankChoices {
    box-sizing: border-box;
    width: 66%;
    float: left;
}

#bankChoices label {
    float: left;
    width: 120px;
    margin: 7px;
    background-color: #EFEFEF;
    border-radius: 4px;
    border: 1px solid #D0D0D0;
    overflow: auto;
}

#bankChoices label span {
    text-align: center;
    font-size: 30px;
    padding: 30px 0px;
    display: block;
}

#bankChoices label input {
    position: absolute;
    top: -20px;
}

#bankChoices input:checked + span {
    background-color: #404040;
    color: #F7F7F7;
}

/*bay choices*/
#bayChoices {
    box-sizing: border-box;
    width: 32%;
    float: right;
}

#bayChoices label {
    float: left;
    width: 110px;
    margin: 7px;
    background-color: #EFEFEF;
    border-radius: 4px;
    border: 1px solid #D0D0D0;
    overflow: auto;
}

#bayChoices label span {
    text-align: center;
    font-size: 30px;
    padding: 30px 0px;
    display: block;
}

#bayChoices label input {
    position: absolute;
    top: -20px;
}

#bayChoices input:checked + span {
    background-color: #404040;
    color: #F7F7F7;
}

.returnsCreditList {
    max-width: none;
}

.returnsCreditList li {
    margin: 10px 0;
    line-height: 1.8em;
}

.inventoryTable {
    margin: 0 auto;
}

.inventoryTable td, td {
    padding: 20px 10px;
}

.container {
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
}

.inventorySearchContainer {
    margin-top: 20px;
    text-align: center;
}

.inventorySearchContainer select {
    display: inline-block;
    width: 25%
}

.inventorySearchContainer div {
    display: inline-block;
}

.inventorySearchContainer input {
    width: auto;
    margin-left: 20px;
    font-size: 1em;
}

.inventorySearchContainer button {
    margin-left: 20px;
}

.locationSearchContainer {
    text-align: center;
    width: auto;
}

.locationSearch select, .locationSearch input, .locationSearch button {
    width: auto;
}

.locationSearch div {
    display: inline-block;
}

.locationSearch input {
    font-size: 1em;
}

.locationSearch input, .locationSearch button {
    margin-left: 20px;
}

.locationMoveReason {
    margin: 15px 0;
}

.locationMoveReason select {
    width: 25%;
}

.locationMoveReason input[type="text"] {
    width: 25%;
    margin-left: 30px;
    font-size: 1em;
}

button {
    font-size: 1em;
}

/* WHY PICNIC */
input[type="checkbox"] {
    opacity: 1;
}

.locationMoveReason .checkboxWhyPicnic {
    margin-left: 20px;
}

.locationMoveReason .checkboxWhyPicnic label {
    float: none;
}

.locationMoveReason .checkboxWhyPicnic input {
    position: relative;
    top: -13px;
}

.mainNav {
    display: flex;
    flex-direction: column;
    align-items: center;
    display: block;
    width: 800px;
    margin: 20px auto;
}

.mainNav a {
    font-size: x-large;
    line-height: 4em;
    width: 260px;
    float: left;
    text-align: center;
    text-decoration: none;
    text-transform: uppercase;
    border: 5px solid white;
}

.hidden{
    display: none;
}

input, select{
    margin-bottom : 5px;
    min-height : 2rem;
}

.fake-table{
    width : 100%;
}

.fake-table thead tr:first-child{
    background: #eee;
}

.fake-table tr:nth-child(even){
    background: #eee;
}

.fake-table tr:nth-child(odd){
    background: #aaa;
}

.users-move{
    background: rgba(255, 179, 39, 1) !important;
}

.taken-move{
    background: rgba(255, 179, 39, 0.4) !important;
}

.ft-even{
    background: #eee;
}

.ft-odd{
    background: #aaa;
}


.input-receiving-wrapper{
  width : 15%;
  padding: 5px;
  float: left;
}

.locationTable{
  width : 100%;
}

.location-table-wrapper{
  /*width: 20%;*/
}

.ttl-0-0{
  background: #FF615D!important;
}
.ttl-1-3{
  background: #FFF15A!important;
}
.ttl-4-10{
  background: #64FFAC!important;
}
.ttl-11-x{
  background: #64FFAC!important;
}
.prd-insert{
  background: #FFF;
}

.selected-return{
  background: #2FFF8C!important;
}

#putaway ul li{
  margin-bottom : 20px;
  font-size: 30px;
}

#putaway label {
  font-size: 36px;
}

#putaway input{
  font-size: 36px;
}

.dead {
  display: none;
}

.active {
  block: inherit;
}

.user-button {
  margin-right: 15px;
}

.ir-title-wrapper{
  width : 40%;
  float: left;
}

.location-table-wrapper{
  float : left;
  padding-left: 10px;
}

#title-bar{
  font-size: 2em;
  text-align: center;
  background: rgb(0, 116, 217) none repeat scroll 0% 0%;
}

#login-page label{
  font-size: 3rem;
}

#login-page input, #login-page button{
  font-size: 2.5rem;
}

#accounts-list{
  width: 100%;
  display: block;
}

#accounts-list li{
  width : 33%;
  float:left;
  margin-bottom: 5px;
  margin-top: 5px;
}

.locations-cl{
  clear : both;
}

.locations-cl ul li{
  float : left;
  margin : 10px;
}

.vnap-e{
  background: #8C2B2B;
}

.stdp-e{
  background: #1D541D;
}

.stdpcab-e{
  background: #1F1202;
}

.modal-close-btn{
  position: fixed;
  left: 1%;
  top: 4%;
  background : red;
}


.print-radio{
    opacity: 1;
    margin-top: -15px;
    margin-left: 35px;
    width: 15px;
}

#error-page{
    text-align: center;
    margin: 0 auto;
}

#error-page h1{
    color: red;
    margin-top: 25px;
    margin-bottom: 50px;
}

.print-sort-selection > input {
  position: relative;
  min-height: 0;
  height: auto;
  margin: 0;
  margin-right: 15px;
}
.print-sort-selection > label {
  float: none;
}
.print-type-selection {
  display: flex;
}
.print-type-selection > input {
  position: relative;
  min-height: 0;
  height: auto;
  margin: 0;
  margin-right: 15px;
}
.print-type-selection > label {
  float: none;
}
